<template>
  <div class="outer-mysubmit">
    <van-sticky>
      <div class="top-outer2" v-show="isPublic">
        <div class="left type-select">
          <span class="type-css">审批类型：</span>
          <van-dropdown-menu active-color="#006C50" class="drop">
            <van-dropdown-item
              v-model="valueType"
              :options="option1"
              @change="changeSubmitType"
            />
          </van-dropdown-menu>
        </div>
        <div class="left">
          <span class="type-css">审批状态：</span>
          <van-dropdown-menu active-color="#006C50" class="drop">
            <van-dropdown-item
              v-model="value1"
              :options="option2"
              @change="changeSubmitType"
            />
          </van-dropdown-menu>
        </div>
        <!-- <div class="right" @click="selectTime">
          <span>时间：</span>
          <span>{{ date }}</span>
        </div> -->
      </div>
    </van-sticky>
    <van-calendar
      v-model="showDate"
      @confirm="onConfirm"
      :max-date="maxDate"
      :min-date="minDate"
      color="#006C50"
    />
    <div class="mlist">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          class="van-clearfix"
          v-model="loading"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          :finished="finished"
          finished-text=""
          @load="onLoad"
        >
          <template v-if="dataList && dataList.length > 0">
            <div
              class="groups-outer"
              :key="index"
              v-for="(item, index) in dataList"
            >
              <div class="item" @click="showProcess(item)">
                <div class="status">
                    <el-tag size="mini" :type="item.result == 'RUNNING' ? '' : item.result == 'REFUSE' ? 'danger' :  item.result == 'CANCEL' || item.result == 'INVALID' ? 'info' : 'success'">{{ item.status }}</el-tag>
                </div>
                <div class="line">
                  <div class="t-title">审批类型名称：</div>
                  <div class="t-txt">{{ item.processDefName }}</div>
                </div>
                <div class="line">
                  <div class="t-title">申请单号：</div>
                  <div class="t-txt">{{ item.instanceId }}</div>
                </div>
                <div class="line">
                  <div class="t-title">提交时间：</div>
                  <div class="t-txt">{{ item.startTime }}</div>
                </div>
                <!-- <div class="line">
                    <div class="t-title">发起人：</div>
                    <div class="t-txt">{{ item.owner.name }}</div>
                  </div> -->
                <div class="line" v-if="item.finishTime">
                  <div class="t-title">结束时间：</div>
                  <div class="t-txt">{{ item.finishTime }}</div>
                </div>
                <div class="line">
                  <div class="t-title">摘要信息：</div>
                  <div class="t-txt">
                    <form-abstracts :value="item.formAbstracts"/>
                  </div>
                </div>
                <div class="line">
                  <div class="t-title">当前节点：</div>
                  <div class="t-txt">{{ item.taskName }}</div>
                </div>
                <div class="line">
                  <div class="t-title">已耗时：</div>
                  <div class="t-txt">{{ getDuration(item) }}</div>
                </div>
                <div class="btn-outer">
                  <el-button v-show="item.result == 'CANCEL'" plain type="danger" @click.stop="delInstance(item)" size="small"
                    >删除</el-button>
                  <el-button type="primary" @click="reSubmit(item)" size="small" v-show="isPublic"
                    >再次提交</el-button>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="no-data">
              <img src="../../../assets/image/n5.png" class="img" />
              <!-- <img src="../../assets/image/logo.png"/> -->
              <!-- <img src="../../assets/image/n5.png" class="img" /> -->
              <span class="txt">暂无内容</span>
            </div>
          </template>
        </van-list>
      </van-pull-refresh>
    </div>

    <el-drawer
      :size="isMobile ? '100%' : '500px'"
      direction="rtl"
      title="审批详情"
      :visible.sync="processVisible"
    >
      <instance-preview
        v-if="processVisible"
        :instance-id="selectInstance.instanceId"
        @handler-after="handlerAfter"
      ></instance-preview>
    </el-drawer>

    <w-dialog
      v-if="!isMobile"
      :title="`发起 - ${selectInstance.processDefName}`"
      closeFree
      width="1000px"
      v-model="openItemDl"
      okText="提 交"
      @cancel="openItemDl = false"
      @ok="submitForm"
    >
      <initiate-process
        ref="processForm"
        :node-id="selectInstance.nodeId"
        :instance-id="selectInstance.instanceId"
        :code="selectInstance.formId"
        v-if="openItemDl"
        @ok="openItemDl = false"
      ></initiate-process>
    </w-dialog>
  </div>
</template>

<script>
import taskApi from "@/api/processTask";
import moment from "moment";
import InstancePreview from "../approval/ProcessInstancePreview";
import InitiateProcess from "../InitiateProcess";
import { getProcTag } from "@/utils/ProcessUtil";
import uniWebview from "@dcloudio/uni-webview-js";
import FormAbstracts from "@/views/workspace/oa/FormAbstracts.vue";
import {delProcessInst} from '@/api/process'
export default {
  name: "MySubmit",
  components: { InstancePreview, InitiateProcess, FormAbstracts },
  data() {
    return {
      total: 0,
      valueSearch: "",
      value1: null,
      valueType: 0,
      error: false,
      isPublic: true,
      value2: 0,
      result: [],
      finished: false,
      refreshing: false,
      showDate: false,
      option1: [],
      option2: [
        { text: "全部", value: null },
        { text: "审批中", value: 1 },
        { text: "办理中", value: 6 },
        { text: "撤销结束", value: 2 },
        { text: "正常结束", value: 3 },
        { text: "拒绝结束", value: 4 },
        { text: "流程作废", value: 5 },
        { text: "业务完成", value: 7 },
      ],
      date: moment().format("YYYY-MM-DD"),
      maxDate: moment().toDate(),
      minDate: moment().subtract(30, "days").toDate(),
      params: {
        pageSize: 10,
        pageNo: 0,
        // finished: null,
        // code: "",
      },
      openItemDl: false,
      selectInstance: {},
      loading: false,
      processVisible: false,
      formList: [],
      dataList: [],
    };
  },
  computed: {
    isMobile() {
      return window.screen.width < 450;
    },
  },
  created() {
    //this.getMySubmitTypeList();
  },
  mounted() {},
  methods: {
    getProcTag,
    sendTitle() {
      uniWebview.postMessage({
        data: { name: "协同-我的请求", flag: "title" },
      });
    },
    changeSubmitType() {
      this.dataList = [];

      this.params.pageNo = 1;
      this.getSubmittedList();
    },
    onRefresh() {
      // 清空列表数据
      // console.log("刷新了")
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
        this.params.pageNo = 1;
      } else {
        //this.dataList = [];
        //加载下一页面的时候改下起始页数
        this.params.pageNo = this.params.pageNo + 1;
        if (this.error) {
          this.params.pageNo = 1;
        }
      }
      // 如果是外部报修表单，只查询指定的流程
      if (this.$route.query.openId && this.$route.query.instanceId) {
        this.getAssignedList();
      } else {
        this.getSubmittedList();
      }
    },
    onConfirm(date) {
      this.showDate = false;
      this.date = moment(date).format("YYYY-MM-DD");
    },
    onSearch(value) {},
    selectTime() {
      this.showDate = true;
    },
    getAssignedList() {
      taskApi
        .getAssignedList(this.$route.query.instanceId).then((rsp) => {
          this.loading = false;
          this.isPublic = false;
          this.total = rsp.data.total;
          this.dataList = [...this.dataList, ...rsp.data.records];
          if (this.dataList.length < this.total) {
          } else {
            this.finished = true;
          }
        })
        .catch((e) => {
          this.error = true;
        });
    },
    getSubmittedList() {
      if (this.option1.length > 0) {
        // console.log("有类型了")
        this.requestData();
      } else {
        //  console.log("没有类型有类型了")
        taskApi
          .mySubmitTypeList({})
          .then((rsp) => {
            let typeList = rsp.data.filter(
              (item) => item.id != 1 && item.id != 2
            );
            let ArrFilterDatas = [];
            typeList.map((item) => {
              ArrFilterDatas = [...ArrFilterDatas, ...item.items];
            });

            let temArr = [
              {
                formName: "全部",
                formId: 0,
              },
              ...ArrFilterDatas,
            ];
            this.option1 = temArr.map((item, index) => {
              return {
                text: item.formName,
                value: item.formId,
              };
            });
            // console.log("加载完类型了"+JSON.stringify(this.params));
            this.requestData();
          })
          .catch((e) => {
            // this.error = true;
            //this.loading = false;
          });
      }
    },
    requestData() {
      this.sendTitle();

      let paramsObj = {
        ...this.params,
        // formName: this.valueType > 0 ? this.option1[this.valueType].text : "", //审批类型
        code: this.valueType != 0 ? this.valueType : "", //审批类型
        finished: this.value1, //审批状态
      };
      taskApi
        .getUserSubmittedListFilter(paramsObj)
        .then((rsp) => {
          this.loading = false;
          this.total = rsp.data.total;
          this.dataList = [...this.dataList, ...rsp.data.records];
          //console.log("还有下一页没看看当前长度"+this.dataList.length+"`总长度```"+ this.total)
          if (this.dataList.length < this.total) {
          } else {
            //  console.log("大于总数啊，没有下一页了")
            this.finished = true;
          }
        })
        .catch((e) => {
          this.error = true;
          //this.loading = false;
        });
    },
    delInstance(row){
      this.$confirm('删除后将失去该流程实例所有数据且无法恢复，是否继续 ?', '警告', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delProcessInst(row.instanceId).then(rsp => {
         this.dataList = [];
         this.params.pageNo = 1;
        //this.onRefresh()
          this.getSubmittedList()
          this.$ok(rsp, '删除流程实例成功')
        }).catch(err => {
          this.$err(err, '删除流程实例失败')
        })
      })
    },
    reSubmit(row) {
      this.selectInstance = row;
      this.openItemDl = true;
      if (row.modelNo != "" && row.modelNo != null) {
        this.$store.commit("clearTickCartList");
        let carList = [
          {
            userName: "",
            userPhone: "",
            carListbyUser: [],
          },
        ];

        console.log("外部表单的页面");
        // this.$message.warning("暂未开通，敬请期待!");
        this.$store.commit("setTickCarList", carList);
        // this.$router.push({
        //   path: "/mbinitiate",
        //   query: { code: item.formId, modeNo: item.modelNo },
        // });
        this.$router.push({
          path: "/mbinitiate",
          query: { code: row.formId, modeNo: row.modelNo },
        });
        //业务协同的提交审批
      } else {
        //以前的提交审批
        this.$router.push("/mbinitiateDefault?code=" + row.formId);
      }
    },
    submitForm() {
      this.$refs.processForm.validate((validForm, validProcess) => {
        if (!this.isMobile) {
          if (validForm && validProcess) {
            this.$refs.processForm.submit();
          } else {
            this.$message.warning("请完善表单/流程选项😥");
          }
        }
      });
    },
    showProcess(row) {
      this.processVisible = true;
      this.selectInstance = row;
    },
    getDuration(row) {
      let end = this.$isNotEmpty(row.finishTime)
        ? row.finishTime
        : moment().format("YYYY-MM-DD HH:mm:ss");
      return this.$timeCoverStr(row.startTime, end);
    },
    handlerAfter() {
      this.processVisible = false;
      this.params.pageNo = 1;
      this.getSubmittedList();
      this.dataList = [];
    },
  },
  // watch: {
  //   params: {

  //     deep: true,
  //     handler() {
  //       console.log("有变化了我要更新")
  //       this.getSubmittedList();
  //     },
  //   },
  // },
};
</script>
<style scoped lang="less">
.type-select {
  /deep/ .van-dropdown-menu__title {
    width: 12rem;
  }
}
.btns {
  display: flex;
  justify-content: flex-end;
  .el-button--text {
    background-color: #006c50;
    color: #fff;

    padding: 0.5rem 0.7rem;
    font-size: 1.3rem;
    text-align: center;
    border-radius: 0.5rem;
  }
}
.outer-mysubmit {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  background-color: #fff;
  /deep/ .van-checkbox__icon {
    font-size: 2rem;
  }
  /deep/ .van-calendar__footer {
    .van-button {
      width: 100%;
    }
  }
  /deep/.van-dropdown-menu__bar {
    box-shadow: none !important;
    background-color: #fff;
  }
  /deep/ .el-drawer__header {
    font-size: 1.6rem !important;
    color: #333;
  }
  /deep/ .el-drawer__close-btn {
    font-size: 1.6rem !important;
    color: #333;
  }
  /deep/ .van-dropdown-menu__title {
    font-size: 1.3rem;
  }
  /deep/ .van-search {
    padding: 0;
    .van-icon {
      font-size: 1.5rem;
    }
  }
  /deep/ .van-cell {
    line-height: 2.3rem;
    font-size: 1.3rem;
  }
  /deep/ .van-dropdown-menu__bar {
    height: 3rem;
  }
  /deep/ .van-sticky--fixed {
    background-color: #fff;
  }
  .groups-outer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    .btn-outer {
      display: flex;
      justify-content: flex-end;
      /deep/ .el-button--small {
        border-radius: 1.5rem;
      }
    }
  }
  .top-outer {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    padding: 0 2rem;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;

    .search {
      width: 50vw;
    }
    .right {
      font-size: 1.3rem;
    }
    .more-submit {
      padding: 1rem 1.5rem;
      background-color: #006c50;
      color: #fff;
      font-size: 1.3rem;
      border-radius: 2rem;
    }
  }
  .top-outer2 {
    margin-top: 1rem;
    display: flex;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    font-size: 1.3rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    .left {
      display: flex;
      align-items: center;
      .type-css {
        padding-bottom: 0.18rem;
      }
    }
  }
  .mlist {
    width: 100%;
    padding: 2rem;
    /deep/ .van-checkbox-group {
      .van-checkbox__icon {
        margin-right: 1rem;
      }
      .van-checkbox__label {
        flex: 1;
      }
    }
    .item {
      padding: 0.8rem;
      flex: 1;

      background-color: #fff;
      border-radius: 1.5rem;
      box-sizing: border-box;
      box-shadow: 0px 0.2rem 1rem rgba(1, 12, 23, 0.06);

      margin-bottom: 1.5rem;
      position: relative;
      .line {
        display: flex;
        align-items: flex-start;
        padding: 0.5rem 0;
        .t-title {
          width: 12rem;
          font-size: 1.3rem;
          color: #333;
        }
        .t-txt {
          font-size: 1.3rem;
          color: #666;
        }
      }
      .status {
        position: absolute;
        top: 0;
        right: 1rem;
        font-size: 1.3rem;
        // background: #eef5f3;
        // color: #006c50;
        line-height: 2rem;
        height: 2rem;
        border-radius: 0.5rem;
        padding: 0rem 0.8rem;
        box-sizing: border-box;
      }
    }
  }
}
</style>

